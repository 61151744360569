<template>
  <div class="Footer">
    <b-row
      class="connect-us padding-content"
      style="margin: 0"
      align-h="center"
    >
      <b-col cols="12">
        <b-row>
          <b-col lg="3" cols="10" class="footer-col">
            <p class="footer-title">解决方案</p>
            <p class="footer-subtitle" @click="changeUrl('2-1-1')">
              三维可视化解决方案
            </p>
            <p class="footer-subtitle" @click="changeUrl('2-1-2')">
              材料设计与器件仿真解决方案
            </p>
            <p class="footer-subtitle" @click="changeUrl('2-3-3')">
              工业数字孪生解决方案
            </p>
            <p class="footer-subtitle" @click="changeUrl('2-4-1')">
              高性能计算资源解决方案
            </p>
          </b-col>
          <b-col lg="2" cols="10" class="footer-col">
            <p class="footer-title">软件产品</p>
            <p class="footer-subtitle" @click="changeUrl('2-1')">FemTCAD</p>
            <p class="footer-subtitle" @click="changeUrl('2-2')">SHINE</p>
            <p class="footer-subtitle" @click="changeUrl('2-3')">SigSimu</p>
            <p class="footer-subtitle" @click="changeUrl('2-4')">VGMS</p>
          </b-col>
          <b-col lg="2" cols="10" class="footer-col">
            <p class="footer-title">关于复数</p>
            <p class="footer-subtitle" @click="changeUrl('4')">公司简介</p>
            <p class="footer-subtitle" @click="changeUrl('4-1')">创始人</p>
            <p class="footer-subtitle" @click="changeUrl('1')">联系我们</p>
          </b-col>
          <b-col lg="3" offset-lg="2" cols="10" class="footer-col">
            <b-row>
              <b-col lg="6" cols="6" align-self="center" class="text-lg-center">
                <img
                  class="footer-logo"
                  src="@/assets/images/footer/fushulogo-word-white.png"
                  @click="changeUrl('5')"
                />
              </b-col>
              <b-col lg="6" cols="6" align-self="center" class="text-lg-center">
                <img class="footer-QQ" src="@/assets/images/QQ/QQ.jpg" />
                <p class="footer-QQ-text">欢迎咨询</p>
                <p class="footer-QQ-text">QQ:2093982637</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="text-lg-center">
          <p class="footer-text">
            Copyright © 2022 苏州复数智能科技有限公司. All rights reserved.<br
              class="d-md-none"
            />
            <a
              style="color: rgba(255, 255, 255, 0.801)"
              href="https://beian.miit.gov.cn/"
              >苏ICP备2022005355号</a
            >
          </p>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {}
  },
  methods: {
    changeUrl(index) {
      this.$store.commit('changeTopNav', index)
      if (index === '2-1-1') {
        this.$router.push({
          path: '/solutions/3ds/sci',
        })
      } else if (index === '2-1-2') {
        this.$router.push({
          path: '/solutions/material/sci',
        })
      } else if (index === '2-3-3') {
        this.$router.push({
          path: '/solutions/digital-twin',
        })
      } else if (index === '2-4-1') {
        this.$router.push({
          path: '/solutions/hpc/server-configuration',
        })
      } else if (index === '2-1') {
        this.$router.push({
          path: '/products/femtcad',
        })
      } else if (index === '2-2') {
        this.$router.push({
          path: '/products/shine',
        })
      } else if (index === '2-3') {
        this.$router.push({
          path: '/products/sigsimu',
        })
      } else if (index === '2-4') {
        this.$router.push({
          path: '/products/vgms',
        })
      } else if (index === '4') {
        this.$router.push({
          path: '/fs/about',
        })
      } else if (index === '1') {
        this.$router.push({
          path: '/fs/contact',
        })
      } else if (index === '5') {
        this.$router.push({
          path: '/',
        })
      } else if (index === '4-1') {
        this.$router.push({
          path: '/fs/founder',
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.Footer {
  .connect-us {
    padding: 5rem 0 2.8125rem;
    background: #0074ff;
    .footer-title {
      color: rgba(255, 255, 255, 0.801);
      font-weight: 600;
      font-size: 1.25rem;
    }
    .footer-subtitle {
      cursor: pointer;
      color: rgba(255, 255, 255, 0.801);
      margin-bottom: 0.3125rem;
    }
    .footer-subtitle:hover {
      text-decoration: underline;
    }
    .footer-logo {
      width: 100%;
      margin-right: 0.625rem;
      cursor: pointer;
    }
    .footer-QQ {
      width: 80%;
      margin-bottom: 0.3125rem;
    }
    .footer-text {
      font-size: 0.875rem;
      color: rgba(255, 255, 255, 0.801);
      margin-top: 2.5rem;
    }
    .footer-QQ-text {
      font-size: 0.875rem;
      margin-bottom: 0;
      color: rgba(255, 255, 255, 0.801);
    }
    .footer-col {
      margin-bottom: 1.875rem;
    }
  }
  @media only screen and (min-width: 375px) and (max-width: 575.98px) {
    .connect-us {
      padding: 5rem 0 3.75rem !important;
    }
  }
  @media (max-width: 320px) {
    .connect-us {
      padding: 5rem 0 5rem !important;
    }
  }
}
</style>
