import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/index.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import BaiduMap from 'vue-baidu-map'
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ElementUI)
Vue.use(BaiduMap, {
  ak: 'ZH7XF1RouGdOGN7i2tRuCSwBd4N0gWsy'
})
Vue.use(VueClipboard)

Vue.prototype.$width = document.body.clientWidth

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
