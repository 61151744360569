import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  window.scrollTo(0, 0)
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '苏州复数智能科技有限公司',
      content: {
        keywords: '苏州复数智能科技有限公司，复数智科，材料设计，仿真，模拟，物理，软件，算法，三维，可视化，高性能计算，数字孪生，科研，生产制造',
        description: '苏州复数智能科技有限公司，专注数字孪生领域，通过将数字可视化和科学仿真模拟技术结合，为科研和产业客户提供可视化、智能化、自动化解决方案。'
      }
    },
    component: Home
  },
  {
    path: '/fs',
    name: 'Fs',
    component: () => import('../views/Fs.vue'),
    children: [
      {
        path: '/fs/about',
        name: 'About',
        meta: {
          title: '公司简介 | 复数智科',
          content: {
            keywords: '公司简介，苏州复数智能科技有限公司，复数智科，材料设计，仿真，模拟，物理，软件，算法，三维，可视化，高性能计算，数字孪生，科研，生产制造',
            description: '苏州复数智能科技有限公司，是苏州市相城区高层次人才创业领军项目，专注数字孪生领域。公司拥有多位海内外资深技术专家，基于多年底层物理算法开发经验，以材料仿真模拟方向为切入口，已自主研发出FemTCAD、SHINE、SigSimu等软件。我们将数字可视化与科学仿真模拟技术进一步结合，针对具体的产业技术问题，进行全方位、多尺度的整合，致力于打造高度定制化的产业、科研模拟平台和数字孪生应用的全面覆盖。我们目前的业务，主要包括为科研工作者提供科研成果可视化展示，实验仿真和高性能计算资源解决方案 ；为产业客户提供材料设计与器件仿真模拟、产线数字孪生定制化解决方案。'
          }
        },
        component: () => import('../views/Fs/About.vue')
      },
      {
        path: '/fs/founder',
        name: 'Founder',
        meta: {
          title: '创始人 | 复数智科',
          content: {
            keywords: '创始人，苏州复数智能科技有限公司，复数智科，王雪峰博士，材料设计，仿真，模拟，物理，软件，算法，TCAD，ATK，半导体，第一性原理，有限元',
            description: '复数智科创始人/总经理王雪峰博士是苏州大学特聘教授，上海微系统所客座研究员。专注半导体材料和器件，聚焦从第一性原理到有限元，力热光电多个领域的仿真。对新材料行业工艺仿真研发具有深刻的理解和经验，在研发模拟器件的格林函数方法方面深耕多年，掌握相关核心关键技术。'
          }
        },
        component: () => import('../views/Fs/Founder.vue')
      },
      {
        path: '/fs/contact',
        name: 'Contact',
        meta: {
          title: '联系我们 | 复数智科',
      content: {
        keywords: '联系我们，苏州复数智能科技有限公司，复数智科，材料设计，仿真，模拟，物理，软件，算法，三维，可视化，高性能计算，数字孪生，科研，生产制造',
        description: '苏州复数智能科技有限公司地    址：苏州市相城区元和街道嘉元路959号元和大厦415B邮    编：215131Q     Q :  2093982637邮    箱：fusongsoochow@163.com'
      }
        },
        component: () => import('../views/Fs/Contact.vue')
      }
    ]
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/Products.vue'),
    children: [
      {
        path: '/products/femtcad',
        name: 'Femtcad',
        meta: {
          title: 'FemTCAD | 软件产品 | 复数智科',
      content: {
        keywords: 'FemTCAD，有限元方法，软件，苏州复数智能科技有限公司，材料设计，仿真',
        description: 'FemTCAD可用于半导体器件输运性质计算，基于有限元和非平衡格林函数方法，可以通过材料的基本物性参数计算特殊形状结构的器件的输运性质。'
      }
        },
        component: () => import('../views/Products/Femtcad.vue')
      },
      {
        path: '/products/shine',
        name: 'Shine',
        meta: {
          title: 'SHINE | 软件产品 | 复数智科',
      content: {
        keywords: 'SHINE，第一性原理，软件，苏州复数智能科技有限公司，材料设计，仿真',
        description: 'SHINE是基于密度泛函理论和非平衡格林函数方法的量子力学程序，采用实空间投影缀加平面波方法描述离子原子核和价电子的原子轨道基，通过均匀实空间网格表示电子波函数，具有很强的计算收敛性和很高的并行计算能力，适合解决纳米材料的诸多性质。'
      }
        },
        component: () => import('../views/Products/Shine.vue')
      },
      {
        path: '/products/sigsimu',
        name: 'Sigsimu',
        meta: {
          title: 'SigSimu | 软件产品 | 复数智科',
          content: {
            keywords: 'SigSimu，能带理论和有效哈密顿量方法，软件，苏州复数智能科技有限公司，材料设计，仿真',
            description: 'SigSimu是针对薄膜材料的电磁物理性质模拟工具。采用有效质量和有限元紧束缚方法，可以用于模拟在外加电场和磁场下材料中电子的信号响应，包括电子行为导致的电流和磁化等现象、及其对外电磁场的影响。'
          }
        },
        component: () => import('../views/Products/Sigsimu.vue')
      },
      {
        path: '/products/vgms',
        name: 'VGMS',
        meta: {
          title: 'VGMS | 软件产品 | 复数智科',
          content: {
            keywords: 'VGMS，几何体模型的可视化展示，软件，苏州复数智能科技有限公司，材料设计，仿真',
            description: 'VGMS 可用于几何体模型的可视化展示，构建不同尺寸的几何体模型，通过灯光、材质、渲染实现模型的可视化效果呈现。'
          }
        },
        component: () => import('../views/Products/Vgms.vue')
      }
    ]
  },
  {
    path: '/solutions',
    name: 'Solutions',
    component: () => import('../views/Solutions.vue'),
    children: [
      {
        path: '/solutions/3ds/sci',
        name: 'ThreeDsSci',
        meta: {
          title: '科研方向 | 三维可视化解决方案 | 复数智科',
          content: {
            keywords: '科研，期刊封面，苏州复数智能科技有限公司，仿真，三维，可视化，数字孪生',
            description: '科研可视化逐渐成为中高档次论文的必需品和有效补充。通过三维动画和图像设计技术手段，将复杂、抽象的科学原理转化为形象、直观的模型、动画等可视化载体呈现，同时辅以文字和旁白说明，最大程度帮助观众理解和内容传播。适用于项目申请、成果展示、学术交流等诸多场景。'
          }
        },
        component: () => import('../views/Solutions/ThreeDs/Sci.vue')
      },
      {
        path: '/solutions/3ds/edu',
        name: 'Edu',
        meta: {
          title: '教育方向 | 三维可视化解决方案 | 复数智科',
          content: {
            keywords: '教育，虚拟仿真实验室，苏州复数智能科技有限公司，仿真，三维，可视化，数字孪生',
            description: '我们构建的虚拟仿真物理实验室，包含对机械力学、材料中热场、电场、磁场、光场等大量虚拟物理场景及其中的物理机制计算模拟。'
          }
        },
        component: () => import('../views/Solutions/ThreeDs/Edu.vue')
      },
      {
        path: '/solutions/3ds/ind',
        name: 'ThreeDsInd',
        meta: {
          title: '生产制造方向 | 三维可视化解决方案 | 复数智科',
      content: {
        keywords: '生产制造，工业动画，苏州复数智能科技有限公司，仿真，三维，可视化，数字孪生',
        description: '与传统的拍摄方法相比，工业动画具有更灵活的表现形式、更强大的说服力和更生动的效果。'
      }
        },
        component: () => import('../views/Solutions/ThreeDs/Ind.vue')
      },
      {
        path: '/solutions/material/sci',
        name: 'MaterialSci',
        meta: {
          title: '科研方向 | 材料设计与器件仿真解决方案 | 复数智科',
      content: {
        keywords: '科研，苏州复数智能科技有限公司，材料设计，仿真，数字孪生',
        description: '我们团队具有多年的物理过程仿真模拟技术的积淀，熟悉各种材料模拟相关软件，可以根据客户的实际需求将问题简化建模，采用合理的算法用专业软件解决物理问题，对于模拟的结果可以从理论上给予定性的判断，从而保证结果的可靠性。'
      }
        },
        component: () => import('../views/Solutions/Material/Sci.vue')
      },
      {
        path: '/solutions/material/ind',
        name: 'MaterialInd',
        meta: {
          title: '生产制造方向 | 材料设计与器件仿真解决方案 | 复数智科',
          content: {
            keywords: '生产制造，苏州复数智能科技有限公司，材料设计，仿真，数字孪生',
            description: '我们可以针对具体的生产环节分析要求，对材料产业中出现的各种材料物性提供计算模拟服务，帮助客户了解生产过程，优化生产参数，搜寻更高效廉价的新材料。随着半导体技术的深化发展，半导体器件从底层材料到器件功能结构都需要仿真模拟模块的介入。我们可以分析技术症结、辅助以现有的模拟工具、再合理结合部分自己原生开发的功能模块或者接口模块，从产业服务的角度进行多尺度全方位的模拟，帮助用户解决实际的生产设计难题。'
          }
        },
        component: () => import('../views/Solutions/Material/Ind.vue')
      },
      {
        path: '/solutions/digital-twin',
        name: 'DigitalTwin',
        meta: {
          title: '工业数字孪生解决方案 | 复数智科',
          content: {
            keywords: '数字孪生，苏州复数智能科技有限公司，仿真，模拟，三维，可视化，生产制造，工业',
            description: '我们将主要为新材料、半导体行业工厂提供实现制造端和研发段的孪生系统可视化搭建以及仿真模拟服务。通过在虚拟系统内部的分析和优化，帮助工厂在研发端和生产端降本增效。'
          }
        },
        component: () => import('../views/Solutions/DigitalTwin.vue')
      },
      {
        path: '/solutions/hpc/server-configuration',
        name: 'ServerConfiguration',
        meta: {
          title: '服务器配置服务 | 高性能计算资源解决方案 | 复数智科',
          content: {
            keywords: '服务器配置，苏州复数智能科技有限公司，高性能计算',
            description: '复数智科提供完备的服务器配置和解决方案。我们与戴尔、曙光、浪潮、宝德、超微等品牌建立了良好的合作。'
          }
        },
        component: () => import('../views/Solutions/Hpc/ServerConfiguration.vue')
      },
      {
        path: '/solutions/hpc/rental-service',
        name: 'RentalService',
        meta: {
          title: '超算机时租赁服务 | 高性能计算资源解决方案 | 复数智科',
          content: {
            keywords: '超算，机时，苏州复数智能科技有限公司，高性能计算',
            description: '为更好地服务于科研人员，复数智科提供超算机时的租赁服务。超算机时资源丰富，规模庞大，包含CPU机时、GPU机时、大内存机时等。'
          }
        },
        component: () => import('../views/Solutions/Hpc/RentalService.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.content) {
    const head = document.getElementsByTagName('head')
    const meta = document.createElement('meta')
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

export default router
