import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedTabIndex: ''
  },
  mutations: {
    changeTopNav (state, index) {
      state.selectedTabIndex = index
    }
  },
  actions: {
  },
  modules: {
  }
})
