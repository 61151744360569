<template>
    <div class="Head">
        <!-- logo-pc -->
        <div class="d-none d-md-block">
            <b-row
                class="head-logo"
                align-h="center"
                align-v="center"
            >
                <b-col
                    cols="4"
                >
                    <img
                        class="logo"
                        src="../../public/img/logo/homeLogo.jpg"
                    >
                </b-col>
                <b-col
                    cols="8"
                >
                    <div class="head-right-option">
                        <span class="welcome-title">苏州复数智能科技有限公司欢迎您！</span>
                        <a href="tencent://message/?uin=2093982637&Site=&Menu=yes">
                            <i class="QQ iconfont icon-qq" />
                        </a>
                        <a
                            href="mailto:fusongsoochow@163.com"
                            target="_blank"
                        >
                            <i class="email iconfont icon-youjian" />
                        </a>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Head',
    data () {
          return {
          }
        },
        methods: {
          toHome (index) {
            this.$store.commit('changeTopNav', index)
            this.$router.push({
              path: '/'
            })
          }
        }
}
</script>
<style lang="less" scoped>
.Head{
  .head-logo{
    padding: 0.625rem 6.25rem  0;
    .logo{
        width: 17.5rem;
        cursor: pointer;
      }
      .head-right-option{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-right: 8px;
        .welcome-title{
          font-size: 1.125rem;
          margin-right: 3.75rem;
          color: rgba(0, 0, 0, 0.85);
        }
        .QQ{
          font-size: 2.1875rem;
          color: rgba(0, 0, 0, 0.65);
          margin-right: 15px;
        }
        .email{
          font-size: 2.5rem;
          color: rgba(0, 0, 0, 0.65);
        }
      }
  }
}
</style>
