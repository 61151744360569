<template>
    <div
        class="home"
    >
        <!-- 轮播图 -->
        <el-carousel height="38.25rem">
            <el-carousel-item
                v-for="(item,index) in bannerList"
                :key="index"
                :style="{'backgroundImage': 'url(' + item.imageUrl + ')'}"
            >
                <b-row
                    class="banner-info"
                >
                    <b-col
                        md="8"
                        lg="5"
                        align-self="center"
                        class="banner-info-col"
                        offset-lg="1"
                        offset-md="1"
                        cols="10"
                        offset="1"
                    >
                        <h3 class="banner-title">
                            {{ item.title }}
                        </h3>
                        <p>{{ item.sub }}</p>
                        <div @click="changePage(item.tabIndex)">
                            <router-link :to="item.url">
                                点击了解
                            </router-link>
                        </div>
                    </b-col>
                </b-row>
            </el-carousel-item>
        </el-carousel>
        <!-- 纳米之间，复数之始 -->
        <b-row
            align-h="center"
            class="module1-title text-center"
        >
            <b-col
                lg="6"
                cols="11"
            >
                <h2>纳米之间，复数之始</h2>
                <span class="justify"> 苏州复数智能科技有限公司，专注数字孪生领域，通过将数字可视化和科学仿真模拟技术结合，为科研和产业客户提供可视化、智能化、自动化解决方案。</span>
                <button
                    @click="changePage('4')"
                    class="learnMore"
                >
                    了解更多<i class="iconfont icon-youjiantou1" />
                </button>
            </b-col>
        </b-row>
        <!-- 三维可视化解决方案 -->
        <b-row
            class="module2 padding-content"
            align-h="center"
        >
            <b-col
                cols="10"
                lg="6"
            >
                <div
                    class="sketch-map"
                    :style="{'backgroundImage': 'url(' + sketchMap[0].imgPath + ')'}"
                />
            </b-col>
            <b-col
                cols="10"
                lg="5"
                class="module-info"
                align-self="center"
                offset-lg="1"
            >
                <h3 class="home-title-h3">
                    三维可视化解决方案
                </h3>
                <p class="justify">
                    我们拥有全套的可视化制作流程，可快速根据客户实际需求搭建三维数字模型，为后期的虚拟仿真提供基础，并通过三维效果图、三维视频、互动界面、VR场景等方式展示过程全貌。
                </p>
                <div class="module-button-group">
                    <button
                        class="btn"
                        @click="changePage('2-1-1')"
                    >
                        科研
                    </button>

                    <button
                        class="btn"
                        @click="changePage('2-2-1')"
                    >
                        教育
                    </button>

                    <button
                        class="btn"
                        @click="changePage('2-3-1')"
                    >
                        生产制造
                    </button>
                </div>
            </b-col>
        </b-row>
        <!-- 材料设计与器件仿真解决方案 -->
        <b-row
            class="module3 padding-content"
            align-h="center"
        >
            <b-col
                cols="10"
                lg="6"
                order-sm="1"
                order-md="2"
                offset-lg="1"
            >
                <div
                    class="sketch-map"
                    :style="{'backgroundImage': 'url(' + sketchMap[1].imgPath + ')'}"
                />
            </b-col>
            <b-col
                cols="10"
                lg="5"
                class="module-info"
                align-self="center"
                order-sm="2"
                order-md="1"
            >
                <h3 class="home-title-h3">
                    材料设计与器件仿真解决方案
                </h3>
                <p class="justify">
                    针对新型材料，没有现成的商业化软件工具可用时，我们可以设计相关物理模型，编写模拟计算代码，开发定制功能模块。
                </p>
                <div class="module-button-group">
                    <button
                        class="btn"
                        @click="changePage('2-1-2')"
                    >
                        科研
                    </button>

                    <button
                        class="btn"
                        @click="changePage('2-3-2')"
                    >
                        生产制造
                    </button>
                </div>
            </b-col>
        </b-row>
        <!-- 工业数字孪生解决方案 -->
        <b-row
            class="module2 padding-content"
            align-h="center"
        >
            <b-col
                cols="10"
                lg="6"
            >
                <div
                    class="sketch-map"
                    :style="{'backgroundImage': 'url(' + sketchMap[2].imgPath + ')'}"
                />
            </b-col>
            <b-col
                cols="10"
                lg="5"
                class="module-info"
                align-self="center"
                offset-lg="1"
            >
                <h3 class="home-title-h3">
                    工业数字孪生解决方案
                </h3>
                <p class="justify">
                    我们将主要为生产制造业提供实现制造端和研发段的孪生系统可视化搭建以及仿真模拟服务。通过在虚拟系统内部的分析和优化，帮助工厂在研发端和生产端降本增效。
                </p>
                <div class="module-button-group">
                    <button
                        class="btn"
                        @click="changePage('2-3-3')"
                    >
                        点击了解
                    </button>
                </div>
            </b-col>
        </b-row>
        <!-- 高性能计算资源解决方案 -->
        <b-row
            class="module3 padding-content"
            align-h="center"
        >
            <b-col
                cols="10"
                lg="6"
                order-sm="1"
                order-md="2"
                offset-lg="1"
            >
                <div
                    class="sketch-map"
                    :style="{'backgroundImage': 'url(' + sketchMap[3].imgPath + ')'}"
                />
            </b-col>
            <b-col
                cols="10"
                lg="5"
                class="module-info"
                align-self="center"
                order-sm="2"
                order-md="1"
            >
                <h3 class="home-title-h3">
                    高性能计算资源解决方案
                </h3>
                <p class="justify">
                    我们针对每一位客户的实际情况和应用场景，推荐完备的服务器配置和优化的机时资源方案，更有专业的工程师团队为您提供全方面的支持和服务。
                </p>
                <div class="module-button-group">
                    <button
                        class="btn"
                        @click="changePage('2-4-1')"
                    >
                        服务器配置
                    </button>

                    <button
                        class="btn"
                        @click="changePage('2-4-2')"
                    >
                        超算机时租赁
                    </button>
                </div>
            </b-col>
        </b-row>
        <!-- 软件产品 -->
        <b-row
            class="module2 padding-content"
            align-h="center"
            align-v="center"
        >
            <b-col
                cols="10"
                class="module-info"
                lg="4"
            >
                <h3 class="home-title-h3">
                    软件产品
                </h3>
                <p class="justify">
                    基于丰厚的底层物理算法经验，我们在材料仿真模拟方向上已自主研发多款应用软件。
                </p>
            </b-col>
            <b-col
                lg="8"
                cols="10"
            >
                <b-row align-h="center">
                    <b-col
                        lg="4"
                        cols="11"
                        class="cards-col"
                    >
                        <div class="cards">
                            <div class="cards-info">
                                <h6>FemTCAD</h6>
                                <p class="justify">
                                    有限元方法，材料的宏观物性模拟
                                </p>
                            </div>
                            <button
                                class="button1"
                                @click="changePage('2-1')"
                            >
                                more
                            </button>
                        </div>
                    </b-col>
                    <b-col
                        lg="4"
                        cols="11"
                        class="cards-col"
                    >
                        <div class="cards">
                            <div class="cards-info">
                                <h6>SHINE</h6>
                                <p class="justify">
                                    第一性原理，原子尺度的微观结构物性模拟
                                </p>
                            </div>
                            <button
                                class="button2"
                                @click="changePage('2-2')"
                            >
                                more
                            </button>
                        </div>
                    </b-col>
                    <b-col
                        lg="4"
                        cols="11"
                        class="cards-col"
                    >
                        <div class="cards">
                            <div class="cards-info">
                                <h6>SigSimu</h6>
                                <p class="justify">
                                    能带理论和有效哈密顿量方法，特殊材料物性模拟
                                </p>
                            </div>
                            <button
                                class="button3"
                                @click="changePage('2-3')"
                            >
                                more
                            </button>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <!-- 更多交流 -->
        <b-row
            class="module3 padding-content"
            align-h="center"
        >
            <b-col
                cols="11"
                lg="6"
                class="module1-title text-center"
            >
                <h2>
                    更多交流
                </h2>
            </b-col>
            <b-col
                cols="10"
                lg="12"
                class="more-exchanges"
            >
                <b-row align-h="center">
                    <b-col
                        lg="4"
                        cols="11"
                        class="more-exchanges-col"
                    >
                        <div
                            class="more-exchanges-info"
                            @click="changePage('2-1-1')"
                        >
                            <div
                                class="bg-img"
                                :style="{'backgroundImage': 'url(' + bgImg[0].imgPath + ')'}"
                            />
                            <div class="more-exchanges-info-text">
                                <h6>如何在最短时间讲明自己的科研成果？</h6>
                                <p>科研动画/期刊封面/科研插图设计制作服务介绍。</p>
                                <button
                                    class="btn"
                                >
                                    more
                                </button>
                            </div>
                        </div>
                    </b-col>
                    <b-col
                        lg="4"
                        cols="11"
                        class="more-exchanges-col"
                    >
                        <div
                            class="more-exchanges-info"
                            @click="changePage('2-3-2')"
                        >
                            <div
                                class="bg-img"
                                :style="{'backgroundImage': 'url(' + bgImg[1].imgPath + ')'}"
                            />
                            <div class="more-exchanges-info-text">
                                <h6>材料设计与器件仿真应用案例</h6>
                                <p>磁栅尺材料仿真应用案例。</p>
                                <button
                                    class="btn"
                                >
                                    more
                                </button>
                            </div>
                        </div>
                    </b-col>
                    <b-col
                        lg="4"
                        cols="11"
                        class="more-exchanges-col"
                    >
                        <div
                            class="more-exchanges-info"
                            @click="changePage('2-4-2')"
                        >
                            <div
                                class="bg-img"
                                :style="{'backgroundImage': 'url(' + bgImg[2].imgPath + ')'}"
                            />
                            <div class="more-exchanges-info-text">
                                <h6>最新超算机时租赁资源与方案</h6>
                                <p>包含CPU、GPU、大内存机时；可提供高达数十TB的数据存储服务。</p>
                                <button
                                    class="btn"
                                >
                                    more
                                </button>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <!-- 合作伙伴 -->
        <b-row
            class="module2 partners padding-content"
            align-h="center"
            align-v="center"
        >
            <b-col
                cols="11"
                lg="6"
                class="module1-title text-center"
            >
                <h2>合作伙伴</h2>
            </b-col>
            <b-col
                cols="11"
                lg="12"
            >
                <b-row
                    class="partners-logo"
                    align-h="between"
                >
                    <b-col
                        cols="5"
                        lg="3"
                        v-for="(item,index) in partnersLogo"
                        :key="index"
                        class="partners-logo-col"
                        align-self="center"
                    >
                        <img
                            :src="item.url"
                            class="partners-logo-img"
                        >
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
        return {
          bannerList: [
            {
              imageUrl: require('@/assets/images/banner/1.png'),
              title: '高性能计算',
              sub: '多种规格，满足不同计算需求；强劲性能，节省您的宝贵时间。',
              url: '/solutions/hpc/rental-service',
              tabIndex: '2-4-2'
            },
            {
                imageUrl: require('@/assets/images/banner/3.png'),
                title: '三维可视化',
                sub: '仿真系统的基本模块和第一表达。全世界的营销专家、工程师、科学家都正在使用的可视化手段。',
                url: '/solutions/3ds/sci',
                tabIndex: '2-1-1'
            },
            {
              imageUrl: require('@/assets/images/banner/2.png'),
               title: 'SHINE',
              sub: '纳米材料从头计算软件，基于密度泛函理论和非平衡格林函数方法。',
              url: '/products/shine',
              tabIndex: '2-2'
            }
          ],
          sketchMap: [
          {
            imgPath: require('@/assets/images/home/m1.png')
          },
            {
            imgPath: require('@/assets/images/home/m2.png')
          },
            {
            imgPath: require('@/assets/images/home/m3.png')
          },
            {
            imgPath: require('@/assets/images/home/m4.png')
          }

          ],
          bgImg: [
              {
              imgPath: require('@/assets/images/home/moreExchanges/s1.png')
              },
              {
                imgPath: require('@/assets/images/home/moreExchanges/s2.png')
              },
              {
                imgPath: require('@/assets/images/home/moreExchanges/s4.png')
              }
          ],
          partnersLogo: [
            {
              url: require('@/assets/images/footer/SHJD.fw.png')
            },
            {
              url: require('@/assets/images/footer/zgkxy.fw.png')
            },
            {
              url: require('@/assets/images/footer/mcg.fw.png')
            },
            {
              url: require('@/assets/images/footer/szdx.fw.png')
            },
            {
              url: require('@/assets/images/footer/hdkgd.fw.png')
            },
            {
              url: require('@/assets/images/footer/heyang.fw.png')
            },
            {
              url: require('@/assets/images/footer/smd.fw.png')
            },
            {
              url: require('@/assets/images/footer/hzw.fw.png')
            }
          ]
        }
  },
  methods: {
    changePage (index) {
      this.$store.commit('changeTopNav', index)
      if (index === '4') {
        this.$router.push({
          path: '/fs/about'
        })
      } else if (index === '2-1-1') {
        this.$router.push({
          path: '/solutions/3ds/sci'
        })
      } else if (index === '2-2-1') {
        this.$router.push({
          path: '/solutions/3ds/edu'
        })
      } else if (index === '2-3-1') {
        this.$router.push({
          path: '/solutions/3ds/ind'
        })
      } else if (index === '2-1-2') {
        this.$router.push({
          path: '/solutions/material/sci'
        })
      } else if (index === '2-3-2') {
        this.$router.push({
          path: '/solutions/material/ind'
        })
      } else if (index === '2-3-3') {
        this.$router.push({
          path: '/solutions/digital-twin'
        })
      } else if (index === '2-4-1') {
        this.$router.push({
          path: '/solutions/hpc/server-configuration'
        })
      } else if (index === '2-4-2') {
        this.$router.push({
          path: '/solutions/hpc/rental-service'
        })
      } else if (index === '2-1') {
        this.$router.push({
          path: '/products/femtcad'
        })
      } else if (index === '2-2') {
        this.$router.push({
          path: '/products/shine'
        })
      } else if (index === '2-3') {
        this.$router.push({
          path: '/products/sigsimu'
        })
      } else if (index === '2-1-1') {
        this.$router.push({
          path: '/solutions/3ds/sci'
        })
      } else if (index === '2-3-2') {
        this.$router.push({
          path: '/solutions/material/ind'
        })
      } else if (index === '2-4-2') {
        this.$router.push({
          path: '/solutions/hpc/rental-service'
        })
      }
    }

  }
}
</script>
<style lang="less" scoped>
.home{
    /deep/.el-carousel__indicators--horizontal{
      display: none;
    }
    .el-carousel__item:nth-child(2n){
       .banner-info-col{
           .banner-title{
            color: rgba(0, 0, 0, 0.85);
            font-size:3.75rem ;
            font-weight: 600;
          }
          p{
            color:rgba(0, 0, 0, 0.85);
            font-size: 1.125rem;
          }
        }
    }
    .el-carousel__item:nth-child(2n+1){
       .banner-info-col{
           .banner-title{
            color: #fff;
            font-size:3.75rem ;
            font-weight: 600;
          }
          p{
            color:#fff;
            font-size: 1.125rem;
          }

        }
    }
    /deep/.el-carousel__item{
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      .banner-info{
        height: 100%;
        .banner-info-col{
           a{
            background: #0074FF;
            color: #fff;
            display: block;
            padding: 0.625rem 1.875rem;
            width: fit-content;
            border-radius: 9px;
            margin-top:1.875rem;
            font-size: 1.125rem;
          }
        }
      }
    }
    .module1-title{
      margin:3.125rem 0;
      h2{
        color: rgba(0,0,0,.85);
        font-size: 2.5rem;
        font-weight: 600;
      }
      span{
        margin-top: 1.5rem;
        font-size: 1.125rem;
        line-height: 1.75rem;
        color: rgba(0,0,0,.65);
      }
      .learnMore{
          color: #0074FF;
          font-size: 0.875rem;
          font-weight: 600;
          outline: none;
          border: none;
          background: transparent;
      }
    }
    .partners-logo{
      .partners-logo-col{
        margin: 15px 0;
        text-align: center;
        .partners-logo-img{
          width:100%;
          height: auto;
        }
      }
    }
    .sketch-map{
        width: 100%;
        padding-top: 65%;
        margin-bottom: 3.125rem;
        border-radius: 9px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

    }
    .module-info{
        h3{
          color: rgba(0,0,0,.85);
          font-weight: 600;
        }
        p{
          color: rgba(0,0,0,.65);
          font-size: 1.125rem;
          margin: 1.25rem 0;
        }
        .module-button-group{
            display: flex;
            flex-direction: row;
            align-items: center;
            .btn{
                border-radius: 6.25rem;
                border: 0.125rem solid #0074FF;
                width: fit-content;
                padding: 0.3125rem 1.5625rem;
                color: #0074FF;
                outline: none;
                background: transparent;
                margin-right:0.9375rem;
            }
        }
      }
    .module2{
      background-color: #f1f1f1;
    }
    .module2,.module3{
      // margin: 6.25rem 0;
      padding:6.25rem 0;
    }
    .cards-col{
      // padding: 1.25rem;
        .cards{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          height: 280px;
          cursor: pointer;
          border: 1px solid #d7d7d7;
          border-bottom: none;
          border-radius: 9px;
          .cards-info{
            height: calc(100% - 60px);
            width: 100%;
            // padding: 50px 30px 20px;
            text-align: center;
            background: #fff;
            border-top-left-radius: 9px;
            border-top-right-radius: 9px;
              h6{
                margin-bottom: 50px;
                font-size: 1.875rem;
                font-weight: 600;
              }
              p{
                margin-bottom: 0;
                text-align: left;
                color: #7f7f7f;
                font-size: 1rem;
              }
          }
          button{
            height: 60px;
            width: 100%;
            color: #fff;
            font-weight: 600;
            font-size: 1.125rem;
            border-bottom-left-radius: 9px;
            border-bottom-right-radius: 9px;
            outline: none;
            border: none;

          }
          .button3{
            background-color: #00D1FF;
          }
          .button2{
            background-color: #00A2FF;
          }
          .button1{
            background-color: #0074FF;
          }

        }
        .cards:hover{
            box-shadow: 10px 10px 20px #a1a1a1;
        }
    }
    .more-exchanges{
        .more-exchanges-col{
          padding:1.25rem;
          .more-exchanges-info{
            border: 1px solid #d7d7d7;
            border-radius: 9px;
            position: relative;
            cursor: pointer;
            .bg-img{
              width: 100%;
              padding-top:50%;
              border-top-left-radius: 9px;
              border-top-right-radius: 9px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;

            }
            .more-exchanges-info-text{
              // padding: 1.875rem;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 0 1.875rem;
                h6{
                  font-size: 1.25rem;
                  font-weight: 600;
                  color: rgba(0,0,0,.85);
                  margin-top: 25px;
                }
                p{
                    color: rgba(0,0,0,.65);
                    font-size: 1rem;
                }
                .btn{
                  padding: 5px 25px;
                  width: fit-content;
                  border-radius: 100px;
                  color: #0074FF;
                  border: 0.125rem solid #0074FF;
                  font-weight: 600;
                  font-size: 1rem;
                  position: absolute;
                  bottom:30px;
                  outline: none;
                  background: transparent;
                }
            }
          }
          .more-exchanges-info:hover{
            box-shadow: 10px 10px 20px #a1a1a1;
          }
        }
    }
    .partners{
      margin-bottom: 0;
    }
    .btn:hover{
      background-color: #0074FF!important;
      color:#fff!important;
      font-weight: 600!important;
    }
    @media only screen and (min-width: 576px) and (max-width: 769px){
        .more-exchanges-info{
              height:450px;
          }
          .cards-col{
            padding: 20px 0;
            .cards-info{
               padding: 50px 30px 20px;
            }
          }
    }
    @media only screen and (min-width: 375px) and (max-width: 575.98px){
        .more-exchanges-info{
              height:340px;
          }
          .home-title-h3{
            font-size:21px;
          }
          .cards-col{
            padding: 20px 0;
            .cards-info{
               padding: 50px 30px 20px;
            }
          }
          .module-button-group{
            .btn{
                padding: 0.3125rem 20px!important;
                margin-right:10px!important;
            }
        }
    }
    @media (max-width: 320px) {
          .more-exchanges-info{
              height:290px;
          }
          .home-title-h3{
            font-size:20px;
          }
          .cards-col{
            padding: 20px 0;
            .cards-info{
               padding: 50px 30px 20px;
            }
          }
          .module-button-group{
            .btn{
                padding: 0.3125rem 15px!important;
                margin-right:5px!important;
            }
        }
    }
    @media (min-width: 1400px) {
      .more-exchanges-info{
          height:430px;
      }
      .home-title-h3{
        font-size:2.0625rem;
      }
      .cards-col{
        padding: 0 40px;
         .cards-info{
          padding: 50px 10px 20px;
        }
      }
    }
     @media only screen and (min-width: 1200px) and (max-width: 1399.98px){
        .more-exchanges-info{
              height:400px;
          }
          .cards-col{
            padding: 0 15px;
            .cards-info{
               padding: 40px 20px 10px;
            }
          }
    }
     @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        .more-exchanges-info{
              height:370px;
          }
          .cards-col{
            padding: 0 5px;
            .cards-info{
               padding: 30px 20px 10px;
            }
          }
    }
    @media only screen and (min-width: 769px) and (max-width: 991.98px){
         .more-exchanges-info{
              height:430px;
          }
          .cards-col{
            padding: 20px 30px;
            .cards-info{
               padding: 50px 30px 20px;
            }
          }
    }
    @media only screen and (min-width: 320px) and (max-width:375px){
         .more-exchanges-info{
              height:300px;
          }
          .cards-col{
            padding: 20px 0px;
            .cards-info{
               padding: 50px 15px 10px;
            }
          }
    }
}
</style>
