<template>
    <b-container
        fluid
        id="app"
    >
        <Head />
        <!-- nav-pc -->
        <el-menu
            :default-active="navSelected"
            :active="navSelected"
            class="d-none d-md-block el-menu-demo g-head"
            mode="horizontal"
            @select="selectItems"
            background-color="#0074FF"
            text-color="#fff"
            active-text-color="#ffd04b"
        >
            <el-menu-item index="1">
                联系我们
            </el-menu-item>
            <el-submenu index="2">
                <template slot="title">
                    软件产品
                </template>
                <el-menu-item index="2-1">
                    FemTCAD
                </el-menu-item>
                <el-menu-item index="2-2">
                    SHINE
                </el-menu-item>
                <el-menu-item index="2-3">
                    SigSimu
                </el-menu-item>
                <el-menu-item index="2-4">
                    VGMS
                </el-menu-item>
            </el-submenu>
            <el-submenu index="3">
                <template slot="title">
                    解决方案
                </template>
                <el-submenu index="2-1">
                    <template slot="title">
                        科研
                    </template>
                    <el-menu-item index="2-1-1">
                        三维可视化解决方案
                    </el-menu-item>
                    <el-menu-item index="2-1-2">
                        材料设计与器件仿真解决方案
                    </el-menu-item>
                </el-submenu>
                <el-submenu index="2-2">
                    <template slot="title">
                        教育
                    </template>
                    <el-menu-item index="2-2-1">
                        三维可视化解决方案
                    </el-menu-item>
                </el-submenu>
                <el-submenu index="2-3">
                    <template slot="title">
                        生产制造
                    </template>
                    <el-menu-item index="2-3-1">
                        三维可视化解决方案
                    </el-menu-item>
                    <el-menu-item index="2-3-2">
                        材料设计与器件仿真解决方案
                    </el-menu-item>
                    <el-menu-item index="2-3-3">
                        工业数字孪生解决方案
                    </el-menu-item>
                </el-submenu>
                <el-submenu index="2-4">
                    <template slot="title">
                        高性能计算
                    </template>
                    <el-menu-item index="2-4-1">
                        服务器配置服务
                    </el-menu-item>
                    <el-menu-item index="2-4-2">
                        超算机时租赁服务
                    </el-menu-item>
                </el-submenu>
            </el-submenu>
            <el-menu-item index="4">
                公司介绍
            </el-menu-item>
            <el-menu-item index="5">
                复数首页
            </el-menu-item>
        </el-menu>
        <!-- nav-phone -->
        <b-row
            class="d-md-none head-logo-phone"
            align-h="center"
            align-v="center"
        >
            <b-col cols="10">
                <!-- <router-link to="/"> -->
                <span
                    class="logo"
                    @click="phoneToHome()"
                >苏州复数智能科技有限公司</span>
                <!-- </router-link> -->
            </b-col>
            <b-col cols="2">
                <i
                    class="menu iconfont icon-icons_menu"
                    @click="showNav"
                    :class="{menuClicked:navIsShow}"
                />
            </b-col>
            <!-- nav-phone-content -->
            <b-col
                cols="12"
                v-if="navIsShow"
            >
                <ul class="nav-phone-tab">
                    <li
                        @click="changePhoneNav(0)"
                        :class="{NavSelected:PhoneNavSelected===0 }"
                    >
                        <router-link
                            to="/fs/about"
                            class="tab-a"
                        >
                            <i class="iconfont icon-shixiangyoujiantou-" />公司简介
                        </router-link>
                    </li>
                    <li style="margin:0">
                        <el-tree
                            :data="solution"
                            :props="defaultProps"
                            accordion
                            @node-click="handleNodeClick"
                        />
                    </li>
                    <li
                        style="border-bottom:none"
                        @click="changePhoneNav(4)"
                        :class="{NavSelected:PhoneNavSelected===4 }"
                    >
                        <router-link
                            to="/fs/contact"
                            class="tab-a"
                        >
                            <i class="iconfont icon-shixiangyoujiantou-" />联系我们
                        </router-link>
                    </li>
                </ul>
            </b-col>
        </b-row>
        <!-- 测试 -->
        <router-view class="g-content" />
        <Footer class="g-footer" />
        <div v-if="showOptions">
            <div
                class="d-none d-md-block right-options"
            >
                <!-- QQ -->
                <div
                    class="right-options-div QQ-options"
                    @click.stop="clicked = true"
                >
                    <i class="right-QQ iconfont icon-qq" />
                </div>
                <!-- QQContent -->
                <div
                    v-if="clicked"
                    class="QQ-img"
                    ref="QQContent"
                >
                    <p>扫一扫，加QQ好友</p>
                    <img
                        src="@/assets/images/QQ/QQ.jpg"
                    >
                    <!-- <a href="tencent://message/?uin=2093982637&Site=&Menu=yes">
                        <i class="iconfont icon-youjiantou1">点击联系我</i>
                    </a> -->
                    <a
                        target="_blank"
                        href="http://wpa.qq.com/msgrd?v=3&uin=2093982637&site=qq&menu=yes"
                    ><i class="iconfont icon-youjiantou1">点击联系我</i></a>
                </div>
                <!-- email -->
                <a
                    href="mailto:fusongsoochow@163.com"
                    target="_blank"
                >
                    <div class="right-options-div email-options">
                        <i class="right-email iconfont icon-youjian" />
                    </div>
                </a>

                <!-- backtop -->
                <div
                    class="right-options-div toTop"
                    @click="toTop"
                >
                    <i class="right-top iconfont icon-zhiding2" />
                </div>
            </div>
        </div>
        <!-- footer-phone -->
        <b-row class="d-md-none footer-phone text-center">
            <b-col
                cols="4"
                class="footer-phone-col"
            >
                <el-button
                    plain
                    @click="open1"
                    v-clipboard:copy="copy"
                    class="share-button"
                >
                    <i class="iconfont icon-fenxiang1" />
                    <p>分享</p>
                </el-button>
            </b-col>
            <b-col
                cols="4"
                class="footer-phone-col"
                @click="showPopW()"
            >
                <i class="iconfont icon-qq" />
                <p>联系</p>
            </b-col>
            <b-col
                cols="4"
                class="footer-phone-col"
                @click="toTop"
            >
                <i class="iconfont icon-ziyuan" />
                <p>顶部</p>
            </b-col>
        </b-row>
        <!-- footer-phone-QQ -->
        <b-row
            v-if="showPopWindows"
            class="d-md-none"
        >
            <b-col
                cols="11"
                class="pop-up-windows-col"
            >
                <div id="pop-up-windows">
                    <div class="pop-up-windows-title">
                        <div class="title-info">
                            <p>
                                扫码沟通，<span>QQ：2093982637</span>
                            </p>
                            <p>邮箱：fusongsoochow@163.com</p>
                        </div>
                        <i
                            class="iconfont icon-guanbi"
                            @click="closePopW()"
                        />
                    </div>
                    <img
                        src="@/assets/images/QQ/QQ.jpg"
                    >
                </div>
            </b-col>
        </b-row>
        <div
            id="mask"
            v-if="showPopWindows"
        />
    </b-container>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Head from '@/components/Head.vue'
export default {
    name: 'App',
    components: {
        Footer,
        Head
    },
    data () {
      return {
          screenWidth: '',
          isShow: true,
          showOptions: false,
          clicked: false,
          navSelected: '5',
          solution: [
            {
              label: '解决方案',
              children: [
                {
                label: '科研',
                children: [
                  {
                    label: '三维可视化解决方案'
                  },
                  {
                    label: '材料设计与器件仿真解决方案'
                  }
                ]
                },
                {
                label: '教育',
                children: [{
                  label: '三维可视化解决方案'
                }]
                },
                {
                  label: '生产制造',
                  children: [
                    {
                      label: '三维可视化解决方案'
                    },
                    {

                      label: '材料设计与器件仿真解决方案'
                    },
                    {
                      label: '工业数字孪生解决方案'
                    }
                  ]
                },
                {
                label: '高性能计算',
                children: [
                  {

                    label: '服务器配置服务'
                  },
                  {

                    label: '超算机时租赁服务'
                  }
                ]
                }
              ]
            },
            {
              label: '软件产品',
              children: [
                {
                label: 'FemTCAD'
                }, {
                label: 'SHINE'
                }, {
                label: 'SigSimu'
                }, {
                label: 'VGMS'
                }
                ]
            }
          ],
          defaultProps: {
            children: 'children',
            label: 'label'
          },
          navIsShow: false,
          PhoneNavSelected: -1,
          showPopWindows: false,
          copy: 'https://www.fstwin.com'
      }
    },
    created () {
      document.addEventListener('click', (e) => {
        if (this.$refs.QQContent) {
            const isSelf = this.$refs.QQContent.contains(e.target)
            if (!isSelf) {
                this.clicked = false
            }
        }
      })
      if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
        Object.assign(
        {},
        this.$store.state,
        JSON.parse(sessionStorage.getItem('store'))
        )
      )
      }
      window.addEventListener('beforeunload', () => {
        sessionStorage.setItem('store', JSON.stringify(this.$store.state))
      })
    },
    mounted () {
        this.screenWidth = this.$width
        var html = document.getElementsByTagName('html')[0]
        if (this.screenWidth < 768 && this.screenWidth > 374) {
            html.style.fontSize = 14 + 'px'
        } else if (this.screenWidth < 374) {
          html.style.fontSize = 12 + 'px'
        }
        window.addEventListener('scroll', this.scrollContent)
    },
    methods: {
      scrollContent () {
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          if (scrollTop > 0) {
            this.showOptions = true
          } else {
             this.showOptions = false
          }
      },
      toTop () {
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      },
      showNav () {
        this.navIsShow = !this.navIsShow
      },
      // phone
      handleNodeClick (data) {
        console.log(data)
        if (data.$treeNodeId === 3) {
             this.$router.push({
                path: '/solutions/3ds/sci'
            })
            this.navIsShow = false
        } else if (data.$treeNodeId === 4) {
            this.$router.push({
                path: '/solutions/material/sci'
            })
            this.navIsShow = false
        } else if (data.$treeNodeId === 6) {
            this.$router.push({
                path: '/solutions/3ds/edu'
            })
            this.navIsShow = false
        } else if (data.$treeNodeId === 8) {
            this.$router.push({
                path: '/solutions/3ds/ind'
            })
            this.navIsShow = false
        } else if (data.$treeNodeId === 9) {
            this.$router.push({
                path: '/solutions/material/ind'
            })
            this.navIsShow = false
        } else if (data.$treeNodeId === 10) {
            this.$router.push({
                path: '/solutions/digital-twin'
            })
            this.navIsShow = false
        } else if (data.$treeNodeId === 12) {
            this.$router.push({
                path: '/solutions/hpc/server-configuration'
            })
            this.navIsShow = false
        } else if (data.$treeNodeId === 13) {
            this.$router.push({
                path: '/solutions/hpc/rental-service'
            })
            this.navIsShow = false
        } else if (data.$treeNodeId === 15) {
              this.$router.push({
              path: '/products/femtcad'
          })
          this.navIsShow = false
        } else if (data.$treeNodeId === 16) {
            this.$router.push({
                path: '/products/shine'
            })
            this.navIsShow = false
        } else if (data.$treeNodeId === 17) {
              this.$router.push({
                  path: '/products/sigsimu'
              })
            this.navIsShow = false
        } else if (data.$treeNodeId === 18) {
              this.$router.push({
                  path: '/products/vgms'
              })
            this.navIsShow = false
        }
        this.PhoneNavSelected = data.$treeNodeId
      },
      changePhoneNav (index) {
        this.PhoneNavSelected = index
         this.navIsShow = false
      },
      showPopW () {
        this.showPopWindows = true
      },
      closePopW () {
        this.showPopWindows = false
      },
      // pc
      selectItems (index) {
        console.log(index)
        this.$store.commit('changeTopNav', index)
        if (index === '1') {
            this.$router.push({
              path: '/fs/contact'
            })
        } else if (index === '5') {
            this.$router.push({
              path: '/'
            })
        } else if (index === '4') {
            this.$router.push({
             path: '/fs/about'
            })
        } else if (index === '2-1') {
            this.$router.push({
              path: '/products/femtcad'
            })
        } else if (index === '2-2') {
            this.$router.push({
             path: '/products/shine'
            })
        } else if (index === '2-3') {
            this.$router.push({
              path: '/products/sigsimu'
            })
        } else if (index === '2-4') {
            this.$router.push({
              path: '/products/vgms'
            })
        } else if (index === '2-1-1') {
            this.$router.push({
              path: '/solutions/3ds/sci'
            })
        } else if (index === '2-1-2') {
            this.$router.push({
               path: '/solutions/material/sci'
            })
        } else if (index === '2-2-1') {
            this.$router.push({
              path: '/solutions/3ds/edu'
            })
        } else if (index === '2-3-1') {
            this.$router.push({
              path: '/solutions/3ds/ind'
            })
        } else if (index === '2-3-2') {
            this.$router.push({
             path: '/solutions/material/ind'
            })
        } else if (index === '2-3-3') {
            this.$router.push({
              path: '/solutions/digital-twin'
            })
        } else if (index === '2-4-1') {
            this.$router.push({
              path: '/solutions/hpc/server-configuration'
            })
        } else if (index === '2-4-2') {
            this.$router.push({
               path: '/solutions/hpc/rental-service'
            })
        }
      },
      phoneToHome () {
        this.navIsShow = false
        this.$router.push({
              path: '/'
          })
      },
      open1 () {
        this.$notify({
          message: '已复制链接',
          type: 'success',
          offset: 100,
          position: 'bottom-right',
          showClose: false
        })
      }
    },
    watch: {
    '$store.state.selectedTabIndex': function (newVal, oldVal) {
        this.navSelected = newVal
      }
    }
}
</script>

<style lang="less">
#app{
  .g-head{
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 9999;
      width: 100%;
  }
  .el-menu-demo{
        padding-right: 6.25rem;
        li{
          float: right;
          font-size: 1.125rem!important;
        }
  }
  .head-logo-phone{
    padding-top: 0.625rem;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 9999;
    background-color: #fff;
    width: 100%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.356);
    .logo{
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 600;
      color:#0074FF;
    }
  .menu{
        font-size: 1.875rem;
        color: rgba(0, 0, 0, 0.65);
        float: right;
      }
  .menuClicked{
    color:#0074FF!important;
  }
  .nav-phone-tab{
    list-style-type: none;
    padding-left: 0;
    border-top: 1px solid #f2f2f2;
    li{
      font-size: 1.125rem;
      margin:0.3125rem 0.625rem;
      color:rgba(0, 0, 0, 0.65);
      .tab-a{
        color: inherit;
      }
    }
  }
  }
  .right-options{
    position: fixed;
    right: 0;
    top:50%;
    z-index: 9999;
    a{
      color:inherit
    }
    .right-options-div{
      height: 50px;
      width: 60px;
      background:#d7d7d7;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      margin: 5px 0;
      position: relative;
      cursor: pointer;
      i{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      .right-QQ{
        font-size: 28px;
      }
      .right-email{
        font-size: 33px;
      }
      .right-top{
        font-size: 33px;
      }
    }
    .right-options-div:hover{
        background: rgba(0, 0, 0, 0.85);
        i{
          color: #fff;
        }
    }
    .QQ-img{
      position: fixed;
      right: 100px;
      top: 50%;
      z-index: 9999;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff;
      border: 1px solid #d7d7d7;
      padding: 15px 10px;
      p{
        margin-bottom: 0;
      }
      img{
        width: 150px;
        height: 150px;
      }
      a{
        background: #0074FF;
        width: 100%;
        color: #fff;
        border-radius: 9px;
        margin-top: 10px;
        display: block;
        text-align: center;
      }
    }
  }
  .NavSelected{
    background: rgb(245, 247, 250);
  }
  .footer-phone{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background:rgba(0, 0, 0,.95);
    .footer-phone-col{
      padding: 3px 0;
      border-right: 1px solid #fff;
      color: #fff;
      i{
        font-size: 20px;
      }
      p{
        margin-bottom: 0;
        font-size: 0.875rem;
      }
      .share-button{
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        background: inherit;
        color:#fff;
         i{
            font-size: 20px;
          }
          p{
            margin-bottom: 0;
            font-size: 0.875rem;
        }
      }
    }

  }
  .pop-up-windows-col{
      z-index: 99999;
      position: fixed;
      top:50%;
      left: 50%;
      transform: translate(-50%,-50%);
      #pop-up-windows{
          background: #fff;
          padding: 15px 10px;
          border-radius: 9px;
          .pop-up-windows-title{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #e5e5e5;
            .title-info{
              span,p{
                word-break: break-all;
                word-wrap: break-word;
                margin-bottom:0;
                font-size: 12px;
              }
            }
            span{
              font-size: 1rem;
            }
            i{
              font-size:1.875rem;
            }
          }
          img{
            width: 100%;
          }
        }
  }
  #mask{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    background-color: #000;
    opacity: 0.6;
  }
}
</style>
<style lang="less">
  .el-menu--horizontal .el-menu {
    li:nth-child(1){
      .el-menu--horizontal{
        position: absolute!important;
        top: 5px!important;
      }
    }
     li:nth-child(2){
      .el-menu--horizontal{
        position: absolute!important;
        top: 41px!important;
      }
    }
     li:nth-child(3){
      .el-menu--horizontal{
        position: absolute!important;
        top: 77px!important;
      }
    }
     li:nth-child(4){
      .el-menu--horizontal{
        position: absolute!important;
        top: 113px!important;
      }
    }
  }
  .el-menu {
    border-bottom: none!important;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.356)!important;
  }
</style>
